.experience {
  &-company {
    h4 {
      font-size: 1.25rem;
      margin-bottom: .25rem;
    }
    padding: .25rem 0;
    ul li {
      font-size: .85rem;
    }
  }
  &-group{
    p {
      font-size: .85rem;
    }
  }
  &-title {
    color: #68747b;
    font:{
      size: 1.1rem;
      style: italic;
      weight: 500 !important;
    }
    margin:.25rem .5rem ;
  }
  &-date {
    color: #212529;
    font:{
      size: .85rem;
    }
  }
  &-description {
    padding:.5rem .5rem 1rem .5rem !important;
  }
}