.section {
  &-title {
    font:{
      size: 2rem;
      weight: 300;
    }
    margin-top: 70px;
    text-transform: uppercase;
  }
  &-subtitle {
    font:{
      size: 2rem;
      weight: 100;
    }
    margin:{
      top: 0;
    }
  }
  &-row-space {
    margin-top: 50px;
    @include media($desktop-small) {
      margin-top: 10px;
    }
  }
  &-content-title {
    font:{
      size: 1.1rem;
      weight: 500;
    }
    margin:{
      top: 10px;
      bottom: 10px;
    }
  }
  &-text {
    font:{
      size: .9rem;
      weight: 300;
    }
    margin:{
      top: 0;
      bottom: 25px;
    }
    padding: 0 10px;
    text-align: justify;
  }
  .IconData g,
  .IconSecurity g,
  .IconFramework g {
    fill: $themeBlue;
  }
  &-floating-frames {
    @include prefix(box-shadow, 0 0 5px rgba($themeDarkBlue, .35), o ms moz webkit);
    @include prefix(border-radius, 5px, o ms moz webkit);
    background-color: $white;
    min-height: 355px;
    padding: 10px;
    position: relative;
    z-index: 1;
    @include media($desktop-small) {
      @include prefix(box-shadow, 0 0 0px transparent, o ms moz webkit);
       min-height: auto;
    }
    &:after {
      @include media($desktop-small) {
        border-bottom: 1px solid #eee;
        content: "";
        display: block;
      }
    }
    &:first-of-type {
      @include prefix(border-radius, 5px 0 0 5px, o ms moz webkit);
    }
    &:nth-of-type(2) {
      @include prefix(box-shadow, 0 2px 15px rgba($themeDarkBlue, .35), o ms moz webkit);
      top: -30px;
      z-index: 2;
      @include media($desktop-small) {
       @include prefix(box-shadow, 0 0 0px transparent, o ms moz webkit);
       top: 0
      }
    }
    &:nth-of-type(3) {
      @include prefix(border-radius, 0 5px 5px 0, o ms moz webkit);
      &:after {
        @include media($desktop-small) {
          border-bottom: 1px solid transparent;
        }
      }
    }
    svg {
      height: 75px;
      margin-top: 20px;
      width: 75px;
    }
  }
  &-btn-group {
    margin-bottom: 15px;
    @include media($desktop-small) {
      display: none;
    }
  }
  &-break {
    background-color: $fontDarkBlue;
    border: 10px solid $fontDarkBlue;
    color: $white;
    height: 45px;
    position: relative;
    width: 100%;
    z-index: 0;
    &:after {
      content: "";
      background:{
        color: transparent;
        position: 0 0;
        size: 10px;
        repeat: repeat;
      }
      opacity: .7;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
  }
  &-alt {
    background-color: $fontDarkBlue;
    border: 10px solid $fontDarkBlue;
    color: $white;
    position: relative;
    z-index: 0;
    &:after {
      content: "";
      background:{
        color: transparent;
        position: 0 0;
        size: auto;
        repeat: repeat;
      }
      opacity: .7;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
  }
  &-divider {
    border-top: 1px solid rgba($white, .5);
  }
  &-closing {
    display: inline-block;
    margin-bottom: 20px;
    svg {
      display: inline-block;
      fill: $white;
      height: 40px;
      vertical-align: middle;
      width: 40px;
    }
    h5 {
      display: inline-block;
      font-size: 2rem;
      margin-left: 10px;
      vertical-align: middle;
    }
  }
  &-footer {
    $footer-color: #e8ebec;
    background-color: $footer-color;
    border-top: 1px solid #8f9ba2;
    padding-top: 40px;
    position: relative;
    z-index: 0;
    &:after {
      content: "";
      background:{
        color: transparent;
        position: 0 0;
        size: auto;
        repeat: repeat;
      }
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: .05;
      z-index: -1;
    }
  }
  a{
    color: $white;
    svg {
      fill: $white;
    }
    &:hover {
      color: rgba($white, .5);
      svg {
        fill: rgba($white, .5);
      }
    }
  }
}