// responsive
$phone-small                : 320px;
$phone-medium               : 480px;
$phone-large                : 512px;
$tablet_over                : 769px;
$tablet                     : 768px;
$animation                  : 801px;
$desktop-small              : 1024px;
$desktop-medium             : 1280px;
$desktop-large              : 1440px;

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1440px;

$sansFontFamily:    "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$Montserrat:        "Montserrat", $sansFontFamily;
// $Rokkitt:            "Rokkitt", $sansFontFamily;

//Colors

$white: #FFFFFF;
$black: #000000;
//Theme Colors
$themeBlue: #00B0F1;
$themeDarkBlue: #223642;
$fontDarkBlue: #374551;
$themeSubtleBlue: mix($white, $themeDarkBlue, 30%);
$themeGray: #919ba1;
$themeRed: #A02525;
$themeOrange: #D13E1F;


$logo: '/images/icons/mm-logo.svg';
$logo-alt: '/images/icons/mm-logo-alt.svg';

$ico-twitter: '/images/icons/ico-twitter.svg';
$ico-twitter-alt: '/images/icons/ico-twitter-alt.svg';

$ico-instagram: '/images/icons/ico-instagram.svg';
$ico-instagram-alt: '/images/icons/ico-instagram-alt.svg';

$ico-linkedin: '/images/icons/ico-linkedin.svg';
$ico-linkein-alt: '/images/icons/ico-linkedin-alt.svg';
